<template>
  <div></div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {}
};
</script>
<style lang='scss' scoped>
</style>
